import React from 'react';
import { graphql } from 'gatsby';
// pages components
import { HomeLayout } from 'pages-components/home';

const HomePage = ({ data: { page }, location, pageContext }: any) => {
  const { frontmatter: frontMatter } = page;

  return (
    <HomeLayout frontMatter={frontMatter} path={location.pathname} locale={pageContext.locale} />
  );
};

export default HomePage;

export const pageQuery = graphql`
  query ($id: String!) {
    page: mdx(id: { eq: $id }) {
      frontmatter {
        title
        slug
        metadata {
          title
          description
          og_title
          og_description
          og_type
          og_image {
            publicURL
            name
          }
          fb_app_id
          twitter_card
          twitter_creator
          twitter_title
          twitter_description
          twitter_image {
            publicURL
            name
          }
          twitter_url
          keywords
        }
        heading {
          title
          subtitle
          titleChangeWords
          logos {
            show
            images {
              src {
                publicURL
                name
              }
              alt
              title
            }
            additionalContent
          }
          slider {
            text
            image {
              src {
                publicURL
                name
              }
              alt
              title
            }
            category
          }
        }
        sections {
          tokenization {
            show
            topButtons {
              text
              link
              icon {
                src {
                  publicURL
                  name
                }
                alt
                title
              }
            }
            contentButtons {
              text
              link
            }
            imageBottom {
              src {
                publicURL
                name
              }
              alt
              title
            }
          }
          democraticAccess {
            show
          }
          friendlyEcosystem {
            show
            image {
              src {
                publicURL
                name
              }
              alt
              title
            }
          }
          spaceForCommunity {
            show
            image {
              src {
                publicURL
                name
              }
              alt
              title
            }
          }
          wroteAboutUs {
            show
            images {
              src {
                publicURL
                name
              }
              alt
              title
            }
          }
          capabilities {
            show
          }
          supportDevelopment {
            show
            image {
              src {
                publicURL
                name
              }
              alt
              title
            }
          }
        }
      }
    }
  }
`;
